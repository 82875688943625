import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectSolicitation, setNewSolicitation } from "../../../action/solicitations"

import InputText from "../../Input/Text"
import Button from "../../Button"

import * as serviceProposalsService from '../../../service/serviceProposals'

import './styles.css'
import { useSearchParams } from "react-router-dom"
import { useWebSocket } from "../../../hooks/useWebSocket"

const PaperNewSolicitation = () => {
    const dispatch = useDispatch()
    const { startWebSocket } = useWebSocket()

    const [ searchParams ] = useSearchParams()
    const uuidSolicitation = searchParams.get("solicitacao")

    const user = useSelector(state => state.user.data)
    const connection = useSelector(state => state.webSocket.connection)
    const solicitation = useSelector(state => state.solicitations.newSolicitation)

    const [loadingApproveButton, setLoadingApproveButton] = useState(false)
    const [loadingRefuseButton, setLoadingRefuseButton] = useState(false)
    const [priceProviderError, setPriceProviderErrro] = useState(false)
    const [priceProvder, setPriceProvider] = useState()
    const [distance, setDistance] = useState(0)

    const titles = {
        bateria: "Carga de Bateria",
        chaveiro: "Chaveiro",
        combustivel: "Combustível",
        mecanico: "Mecânico",
        pneu: "Troca de Pneus",
        reboque: "Reboque"
    }

    const wheelsConditions = {
        lockedWheels: "As rodas estão travadas",
        freeWheels: "As rodas estão livres"
    }

    const calculateDistance = async () => {
        const customerLocation = solicitation.current_location
        const providerLocation = user.company.coords

        const provider = [providerLocation.lat, providerLocation.lng]
        const cliente = [customerLocation.latitude,customerLocation.longitude]

        const origens = `${provider[0]},${provider[1]}`;
        const destinos = `${cliente[0]},${cliente[1]}`;

        const response = await serviceProposalsService.calculateDistance(origens, destinos)
        if (response) setDistance(response.distance)
    }

    const sendServiceProposals = async (serviceProposal) => {
        serviceProposal.action = "sendServiceProposals"

        if (!connection || connection.readyState !== WebSocket.OPEN) {
            startWebSocket(
                user.uuid, 
                user.uuidCompany, 
                "sendServiceProposal", 
                serviceProposal
            )
        } else {
            connection.send(JSON.stringify(serviceProposal))
        }
    }

    const handlePriceProvider = (value) => {
        setPriceProviderErrro(false)
        setPriceProvider(value.replace('.', ','))
    }

    const handleOnRefuse = () => {
        setLoadingRefuseButton(true)
        dispatch(setNewSolicitation({}))
        setLoadingRefuseButton(false)
    }

    const handleOnApprove = async () => {
        setLoadingApproveButton(true)
        if (!priceProvder || priceProvder <= 0) {
            setPriceProviderErrro(true)
            return 
        }
        
        const providerLocation = user.company.coords
        const provider = {
            uuid: user.uuid,
            name: user.name,
            photo: user.photo,
            location: {latitude: providerLocation.lat, longitude: providerLocation.lng}
        }
        
        await sendServiceProposals({
            uuidSolicitation: solicitation.uuid,
            uuidProvider: user.uuid,
            uuidCompany: user.uuidCompany,
            uuidLead: solicitation.uuidLead,
            uuidUser: solicitation.uuidUser,
            priceProvider: Number(priceProvder.replace(',','.')),
            service: solicitation.service,
            distance: distance,
            provider: provider,
            company: user.company,
            origin: "company",
            status: "watingResponse"
        })
        
        dispatch(setNewSolicitation({}))
        setLoadingApproveButton(false)
        setDistance()
        if (uuidSolicitation) {
            window.location.href = '/'
        }
    }

    useEffect(() => {
        if (solicitation) {
            calculateDistance()
        }
    }, [solicitation])

    return (
        <div className="paper-new-solicitation-container">
            <div className="paper-new-solicitation-content">
                <div className="paper-new-solicitation-title">
                    <span>{`${titles[solicitation.service]}`}</span>
                </div>
                <div className="paper-new-solicitation-info">
                    {distance > 0 && <span><label>Deslocamento total:</label> {`${distance.toFixed(2)} km`}</span>}
                    <span><label>Endereço:</label> {solicitation.current_location.formattedAddress}</span>
                    <span><label>Veículo:</label> {solicitation.vehicle}</span>
                    <span><label>Tipo do veículo:</label> {solicitation.vehicleType}</span>
                    <span><label>Descrição do ocorrido:</label> {solicitation.report}</span>
                    
                    {solicitation.service === "pneu" &&
                        <>
                            <span>Condição do pneu:</span>
                            <div>
                                <span>O pneu encontra-se <label >{solicitation.tiresConfig.tire_conditions}</label>.</span>
                                <span>O cliente <label >{`${solicitation.tiresConfig.has_tools === "não" ? "não possui " : "possui "}ferramentas`}</label>.</span>
                                <span>O cliente <label >{`${solicitation.tiresConfig.has_steppe === "não" ? "não possui " : "possui "}estepe`}</label>.</span>
                                {solicitation.tiresConfig.has_steppe === "sim" && 
                                    <span>O estepe <label >{`${solicitation.tiresConfig.steppe_is_full === "não" ? "está vazio" : "está cheio"}`}</label>.</span>
                                }
                            </div>
                        </>
                    }
                    { solicitation.service === "chaveiro" &&
                        <>
                            <span><label>Condição da chave:</label> {solicitation.keyConfig.condition}</span>
                            <span><label>Possui chave reserva:</label> {solicitation.keyConfig.hasExtrakey}</span>
                        </>
                    }
                    { solicitation.service === "reboque" &&
                        <span><label>Condição das rodas:</label> {wheelsConditions[solicitation.wheelsConfig.condition]}</span>
                    }

                    <button onClick={() => dispatch(selectSolicitation(solicitation))}>Clique aqui para ver no mapa</button>
                </div>
                <div>
                    <InputText 
                        label="Qual o valor do serviço?"
                        placeholder="Informe apenas os números"
                        value={priceProvder}
                        error={priceProviderError}
                        helperText={"O valor do serviço não pode menor ou igual a 0."}
                        onChange={(event) => handlePriceProvider(event.target.value)}
                    />
                    {priceProvder &&
                        <span>Valor da proposta sem a taxa: <label className="paper-new-solicitation-price">{Number(priceProvder.replace(',', '.')).toLocaleString("pt-br", {style: "currency", currency: "BRL"})}</label></span>
                    }
                </div>
            </div>
            <div className="paper-new-solicitation-button-content">
                <Button 
                    kind="error"
                    label="Recusar"
                    onClick={handleOnRefuse}
                    loading={loadingRefuseButton}
                />
                <Button 
                    kind="primary"
                    label="Enviar proposta"
                    onClick={handleOnApprove}
                    loading={loadingApproveButton}
                />
            </div>
        </div>
    )
}

export default PaperNewSolicitation